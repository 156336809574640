<template>
    <div class="section">
        <page-title :title="title" class="ml-2"></page-title>
        <b-container fluid>
            <b-card>
                <spinner v-if="refreshData"></spinner>

                <custom-table
                    v-if="!refreshData"
                    :tableConfig="tableConfig"
                    :fields="fields"
                    :items="items"
                    @selectBoxOptionChange="filterInvoices"
                    @toggleForcedTableRefresh="toggleForcedTableRefresh"
                    :forcedTableRefresh="forcedTableRefresh"
                    :page="page"
                ></custom-table>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import CustomTable from '@/components/shared/table/CustomTable';
import { SstRepository } from '@/repositories';
import PageTitle from '@/layouts/components/PageTitle.vue';
import Periods from '@/utils/periods';
import Spinner from '@/components/shared/element/Spinner';
import moment from 'moment';
import { TABLE } from '@/constants';

export default {
    name: 'SstInvoicePaid',
    components: {
        PageTitle,
        CustomTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('sst.pages.paidInvoice.title'),
            items: [],
            fields: [
                {
                    key: 'intervention_request_id',
                    label: this.$t('sst.pages.paidInvoice.table.columns.id'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'sst_bill_ref',
                    label: this.$t('sst.pages.paidInvoice.table.columns.invoice'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ttc_amount',
                    label: this.$t('sst.pages.paidInvoice.table.columns.amount'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'sst_bill_record_date',
                    label: this.$t('sst.pages.paidInvoice.table.columns.registeredDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'payment_validation_date',
                    label: this.$t('sst.pages.paidInvoice.table.columns.validationDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'bap_ref',
                    label: this.$t('sst.pages.paidInvoice.table.columns.bapId'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'bap_paid_amount',
                    label: this.$t('sst.pages.paidInvoice.table.columns.bapAmount'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'bap_paid_date',
                    label: this.$t('sst.pages.paidInvoice.table.columns.paidDate'),
                    sortable: true,
                    class: 'text-left'
                }
                // },
                // {
                //     key: 'show_details',
                //     label: '',
                //     sortable: false,
                //     class: 'text-center'
                // }
            ],
            tableConfig: {
                sortBy: 'sst_bill_record_date',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                currentPage: 1,
                displayCount: true,
                displayTotalRows: false,
                selectBoxOptions: this.getSelectBoxOptions(),
                selectedOptionSelectBox: null
            },
            refreshData: true,
            data: [],
            page: TABLE.PAGE.SST.INVOICE.PAID.ID,
            forcedTableRefresh: false
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.refreshData = true;
            SstRepository.getPayedInvoices()
                .then(response => {
                    this.refreshData = false;
                    this.items = response.data.data;
                    this.data = response.data.data;
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        },
        getSelectBoxOptions() {
            const periods = Periods.generateMonthAndYear();
            periods.unshift({
                value: null,
                text: this.$t('sst.pages.paidInvoice.selectBox.placeholder')
            });
            return periods;
        },
        filterInvoices(selectedOption) {
            this.items = this.data;
            if (selectedOption !== null) {
                this.items = this.items.filter(element => {
                    let payedData = moment(element.bap_paid_date);
                    return payedData.format('MM/YYYY') === selectedOption;
                });
            }
            this.toggleForcedTableRefresh();
        },
        toggleForcedTableRefresh() {
            this.forcedTableRefresh = !this.forcedTableRefresh;
        }
    }
};
</script>
